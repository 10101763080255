import * as React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Head.module.scss";

import analyticsService from "services/analyticsService";
import {
  productCartWhite,
  productCarGreen,
  productPersonGreen,
  productDocumentGreen,
  startFilled,
} from "assets/image";
import { TestPackageType, PaymentType, ClickType } from "types";
import { useTypedSelector, useAppDispatch } from "hooks";
import { setCart } from "store/thunks";
import SubscribePopup from "components/ShoppingCart/SubscribePopup";
import CartNotificationPopup from "components/CartNotificationPopup";
import { getCurrencySymbol } from "helpers";
import Description from "components/Product/Head/Description";
import { useState } from "react";
import Mark from "./Mark";

interface ActivePayments {
  isOneTimePayment: boolean;
  isSubscription: boolean;
  activeSubscriptionPlan: PaymentType;
}

type Props = {
  testPackage: TestPackageType;
};

const getInActiveRange = (
  activeIndex: number,
  length: number,
  index: number,
  imagesNumber: number
): boolean => {
  if (activeIndex === 0) {
    return index < imagesNumber;
  }

  if (activeIndex === length - 1) {
    return index >= length - imagesNumber;
  }

  return index >= activeIndex - 1 && index <= activeIndex + 1;
};

const Head = ({ testPackage }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [mark, setMark] = useState(3);
  const [commentsCount, setCommentsCount] = useState(17);
  const [activeImage, setActiveImage] = React.useState(0);
  const [monthsPlanActive, setMonthsPlanActive] = React.useState(true);
  const [activePayments, setActivePayments] = React.useState<ActivePayments>({
    isOneTimePayment: true,
    isSubscription: false,
    activeSubscriptionPlan: PaymentType.Every3Months,
  });
  const activePaymentType: PaymentType = activePayments.isOneTimePayment
    ? PaymentType.OneTime
    : PaymentType[activePayments.activeSubscriptionPlan];
  const [showPopup, setShowPopup] = React.useState(false);
  const { cart } = useTypedSelector((state) => state.cart);
  const { isAuthorized } = useTypedSelector((state) => state.auth);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const [itemAddedToCart, setItemAddedToCart] = React.useState(false);
  const [notificationVisible, setNotificationVisible] = React.useState(false);
  const activeTestPackage = cart.find(
    (item) => item.testPackageId === testPackage.id
  );
  const isMaxQuantityForPurchaseExcepted =
    !!testPackage.maxQuantityForPurchase &&
    !!activeTestPackage?.quantity &&
    activeTestPackage?.quantity <= testPackage.maxQuantityForPurchase;
  const history = useHistory();
  const { t } = useTranslation();
  const currency = getCurrencySymbol(currentMarket.currency);

  const subscriptions = {
    [PaymentType.OneTime]: "1",
    [PaymentType.Monthly]: "1",
    [PaymentType.Every2Months]: "2",
    [PaymentType.Every3Months]: "3",
    [PaymentType.Every6Months]: "6",
  };

  const successItemAddedToCart = (): void => {
    analyticsService.createGAEvent("add_to_cart", {
      items: [
        {
          item_id: testPackage.id,
          item_name: testPackage.name,
          item_category: testPackage.category,
          price: testPackage.prices.OneTime.price / 100,
        },
      ],
    });

    analyticsService.createFacebookEvent("custom", "AddToCart", {
      contents: [{ id: testPackage.id, name: testPackage.nameT, quantity: 1 }],
      value: (testPackage.prices[activePaymentType].price / 100).toFixed(2),
      currency: "EUR",
    });

    setItemAddedToCart(true);
  };

  const addItemToCart = async (): Promise<void> => {
    setNotificationVisible(false);
    setItemAddedToCart(false);

    if (cart.some((item) => item.paymentType !== activePaymentType)) {
      setItemAddedToCart(false);
    } else if (
      !cart.some((item) => item.testPackageId === testPackage.id) &&
      cart.length === 10
    ) {
      setItemAddedToCart(false);
    } else if (!cart.some((item) => item.testPackageId === testPackage.id)) {
      dispatch(
        setCart([
          ...cart,
          {
            quantity: 1,
            paymentType: activePaymentType,
            testPackageId: testPackage.id,
            testPackageName: testPackage.name,
            testPackageLabId: testPackage.packageLabId,
            price: testPackage.prices.OneTime.price,
            preview: testPackage.photos[0],
            prices: testPackage.prices,
          },
        ])
      );

      successItemAddedToCart();
    } else if (
      activeTestPackage?.paymentType === PaymentType.OneTime &&
      activeTestPackage?.paymentType === activePaymentType &&
      !isMaxQuantityForPurchaseExcepted
    ) {
      const updatedCart = cart.map((item) => {
        return item.testPackageId === testPackage.id
          ? { ...item, quantity: item.quantity + 1 }
          : item;
      });

      dispatch(setCart(updatedCart));

      successItemAddedToCart();
    }

    setNotificationVisible(true);
  };

  React.useEffect(() => {
    analyticsService.createGAEvent("view_item", {
      items: [
        {
          item_id: testPackage.id,
          item_name: testPackage.name,
          item_category: testPackage.category,
          price: testPackage.prices.OneTime.price / 100,
        },
      ],
    });
  }, [testPackage]);

  return (
    <div className={styles.head}>
      {notificationVisible && (
        <CartNotificationPopup
          isSuccessful={itemAddedToCart}
          close={() => setNotificationVisible(false)}
        />
      )}
      {showPopup && (
        <SubscribePopup
          paymentType={activePaymentType}
          testPackageId={testPackage.id}
          preview={testPackage.photos[0]}
          name={testPackage.nameT}
          price={testPackage.prices.Every3Months.price}
          prices={testPackage.prices}
          currency={currentMarket.currency}
          clickType={ClickType.SetType}
          setActivePaymentType={(value) =>
            setActivePayments({
              isOneTimePayment: false,
              isSubscription: true,
              activeSubscriptionPlan: value,
            })
          }
          closePopup={() => setShowPopup(false)}
        />
      )}
      <div className={styles.head__imageBlock}>
        <div className={styles.head__imageBlock__imageContent}>
          <div className={styles.head__mainImageHolder}>
            <img
              className={styles.head__mainImage}
              src={testPackage.photos[activeImage]}
              alt="Main"
            />
          </div>
        </div>
      </div>
      <div className={styles.head__textBlock}>
        <div className={styles.head__title}>{testPackage.nameT}</div>
        <Description
          description={testPackage.descriptionT}
          shortDescription={testPackage.shortDescriptionT}
          subDescription={testPackage.subDescriptionT}
          sampleReport={testPackage.sampleReport}
          tests={testPackage.tests}
          hideTestsList={testPackage.tests.length > 20}
        />
        <Mark mark={mark} commentsCount={commentsCount} />
        <div className={styles.head__prices}>
          <div className={styles.head__price}>
            {monthsPlanActive
              ? testPackage.prices.Every3Months.price / 100
              : testPackage.prices.OneTime.price / 100}
            <span className={styles.head__price__euro}>{currency}</span>
          </div>
          {monthsPlanActive && (
            <div className={styles.head__priceBeforeDiscount}>
              {testPackage.prices.OneTime.price / 100}
              <span className={styles.head__priceBeforeDiscount__euro}>
                {currency}
              </span>
            </div>
          )}
        </div>
        <button
          className={
            isMaxQuantityForPurchaseExcepted
              ? `${styles.head__button} ${styles.head__button_disabled}`
              : styles.head__button
          }
          disabled={isMaxQuantityForPurchaseExcepted}
          onClick={async () => {
            if (isAuthorized) {
              await addItemToCart();
            } else {
              localStorage.setItem("redirectLink", window.location.pathname);
              history.push("/login");
            }
          }}
        >
          <img
            className={styles.head__shoppingCart}
            src={productCartWhite}
            alt="Shopping cart"
          />
          <div className={styles.head__buttonText}>
            {t("Product.Head.Button").toUpperCase()}
          </div>
        </button>
      </div>
    </div>
  );
};

export default Head;
