import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./SubscribePopup.module.scss";

import {
  headerLogo,
  shoppingCartHeart,
  shoppingCartRefresh,
} from "assets/image";
import { PaymentType, Prices, ClickType, Currency } from "types";
import { getCurrencySymbol } from "helpers";

type Props = {
  paymentType: PaymentType;
  testPackageId: string;
  preview: string;
  name: string;
  price: number;
  prices: Prices;
  currency: Currency;
  clickType: ClickType;
  setTestType?: (id: string, type: PaymentType) => void;
  setActivePaymentType?: (activePaymentType: PaymentType) => void;
  closePopup: () => void;
};

const SubscribePopup = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const [selectedSubscription, setSelectedSubscription] =
    React.useState<PaymentType>(props.paymentType);

  const setCartOrType = (): void => {
    if (props.clickType === ClickType.SetType && props.setActivePaymentType) {
      props.setActivePaymentType(selectedSubscription);
      props.closePopup();
    }

    if (props.clickType === ClickType.SetCart && props.setTestType) {
      props.setTestType(props.testPackageId, selectedSubscription);
      props.closePopup();
    }
  };

  return (
    <div className={styles.popup}>
      <div
        className={styles.popup__background}
        onClick={() => props.closePopup()}
      ></div>
      <div className={styles.popup__block}>
        <div className={styles.popup__topBlock}>
          <Link className={styles.popup__logoWrapper} to={`/`}>
            <img src={headerLogo} className={styles.popup__logo} alt="Logo" />
          </Link>
          <div className={styles.popup__title}>
            {t("Cart.SubscribePopup.Title")}
          </div>
          <div
            className={styles.popup__closeIcon}
            onClick={() => props.closePopup()}
          ></div>
        </div>
        <div className={styles.popup__centerBlock}>
          <div className={styles.popup__testContent}>
            <div className={styles.popup__testInfo}>
              <div className={styles.popup__testInfo__imageHolder}>
                <img
                  className={styles.popup__testInfo__image}
                  src={props.preview}
                  alt="Test"
                />
              </div>
              <div>
                <div className={styles.popup__testInfo__name}>{props.name}</div>
                <div className={styles.popup__testInfo__price}>
                  {props.price / 100}
                  <span className={styles.popup__testInfo__price_euro}>
                    {getCurrencySymbol(props.currency)}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.popup__subscriptionOptions}>
              <div className={styles.popup__subscriptionOptions__title}>
                {t("Cart.SubscribePopup.OptionsTitle")}
              </div>
              <label
                className={
                  selectedSubscription === PaymentType.Monthly
                    ? `${styles.inputWrapper} ${styles.inputWrapper_selected}`
                    : styles.inputWrapper
                }
                onClick={() => setSelectedSubscription(PaymentType.Monthly)}
              >
                <div className={styles.popup__inputText}>
                  {t("Cart.SubscribePopup.Monthly")}
                </div>
                <div className={styles.popup__inputDiscount}>
                  {t("Cart.SubscribePopup.Discount")}
                </div>
                <input
                  className={styles.popup__input}
                  type="radio"
                  id="oneTimePlan"
                  checked={selectedSubscription === PaymentType.Monthly}
                  readOnly
                />
                <span className={styles.checkmark}></span>
              </label>
              <label
                className={
                  selectedSubscription === PaymentType.Every2Months
                    ? `${styles.inputWrapper} ${styles.inputWrapper_selected}`
                    : styles.inputWrapper
                }
                onClick={() =>
                  setSelectedSubscription(PaymentType.Every2Months)
                }
              >
                <div className={styles.popup__inputText}>
                  {t("Cart.SubscribePopup.Every2Months")}
                </div>
                <div className={styles.popup__inputDiscount}>
                  {t("Cart.SubscribePopup.Discount")}
                </div>
                <input
                  className={styles.popup__input}
                  type="radio"
                  id="every2MonthsPlan"
                  checked={selectedSubscription === PaymentType.Every2Months}
                  readOnly
                />
                <span className={styles.checkmark}></span>
              </label>
              <label
                className={
                  selectedSubscription === PaymentType.Every3Months
                    ? `${styles.inputWrapper} ${styles.inputWrapper_selected}`
                    : styles.inputWrapper
                }
                onClick={() =>
                  setSelectedSubscription(PaymentType.Every3Months)
                }
              >
                <div className={styles.popup__inputText}>
                  {t("Cart.SubscribePopup.Every3Months")}
                </div>
                <div className={styles.popup__inputDiscount}>
                  {t("Cart.SubscribePopup.Discount")}
                </div>
                <input
                  className={styles.popup__input}
                  type="radio"
                  id="every3MonthsPlan"
                  checked={selectedSubscription === PaymentType.Every3Months}
                  readOnly
                />
                <span className={styles.checkmark}></span>
              </label>
              <label
                className={
                  selectedSubscription === PaymentType.Every6Months
                    ? `${styles.inputWrapper} ${styles.inputWrapper_selected}`
                    : styles.inputWrapper
                }
                onClick={() =>
                  setSelectedSubscription(PaymentType.Every6Months)
                }
              >
                <div className={styles.popup__inputText}>
                  {t("Cart.SubscribePopup.Every6Months")}
                </div>
                <div className={styles.popup__inputDiscount}>
                  {t("Cart.SubscribePopup.Discount")}
                </div>
                <input
                  className={styles.popup__input}
                  type="radio"
                  id="every6MonthsPlan"
                  checked={selectedSubscription === PaymentType.Every6Months}
                  readOnly
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>
          </div>
          <div className={styles.popup__benefits}>
            <div className={styles.popup__benefitsTitle}>
              {t("Cart.SubscribePopup.BenefitsTitle")}
            </div>
            <div className={styles.popup__benefit}>
              <img
                className={styles.popup__benefit__image}
                src={shoppingCartRefresh}
                alt="Refresh"
              />
              <div>
                <div className={styles.popup__benefit__title}>
                  {t("Cart.SubscribePopup.BenefitsSubtitle1")}
                </div>
                <div className={styles.popup__benefit__description}>
                  {t("Cart.SubscribePopup.BenefitsText1")}
                </div>
              </div>
            </div>
            <div className={styles.popup__benefit}>
              <img
                className={styles.popup__benefit__image}
                src={shoppingCartHeart}
                alt="Heart"
              />
              <div>
                <div className={styles.popup__benefit__title}>
                  {t("Cart.SubscribePopup.BenefitsSubtitle2")}
                </div>
                <div className={styles.popup__benefit__description}>
                  {t("Cart.SubscribePopup.BenefitsText2")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.popup__bottomBlock}>
          <div
            className={styles.popup__closeButton}
            onClick={() => props.closePopup()}
          >
            {t("Cart.SubscribePopup.ButtonClose")}
          </div>
          <div
            className={styles.popup__confirmButton}
            onClick={() => setCartOrType()}
          >
            {t("Cart.SubscribePopup.ButtonConfirm")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribePopup;
