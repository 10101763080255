import React, { createContext, useContext, useMemo } from "react";
import useMedia from "use-media";

interface Props {
  children: React.ReactNode;
}

export const MediaQueryContext = createContext({ isMobile: false });

const mediaQueries = {
  mobile: "(max-width: 1180px)",
};

export default function MediaQueryProvider({ children }: Props): JSX.Element {
  const isMobile = useMedia(mediaQueries.mobile);
  const value = useMemo(() => ({ isMobile }), [isMobile]);

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
}

export function useMediaQueryContext(): { isMobile: boolean } {
  return useContext(MediaQueryContext);
}
