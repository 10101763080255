import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { Field, Formik } from "formik";
import dayjs from "dayjs";

import style from "./Signup.module.scss";

import { useAppDispatch, useTypedSelector } from "hooks";
import Spinner from "components/Spinner";
import SuccessMessage from "components/SuccessMessage";
import CustomInput from "components/CustomInput";
import { RegistrationSchema } from "validation";
import { setSignupErrorObject, signup } from "store/actions";
import { signupForm } from "types";
import { LanguageContext } from "context";
import { addLinksToText } from "helpers";

function SignupPage(): JSX.Element {
  const { t } = useTranslation();
  const { signupErrorsObject, signupLoading } = useTypedSelector(
    (state) => state.signup
  );
  const { consents } = useTypedSelector((state) => state.consents);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const [success, setSuccess] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const dispatch = useAppDispatch();
  const { currentLanguage } = React.useContext(LanguageContext);
  const successHandler = (): void => {
    setShowMain(false);
    setSuccess(true);
  };

  const localSignup = (formData: signupForm) => {
    const {
      firstName,
      lastName,
      email,
      sex,
      password,
      repeatPassword,
      agreedToProcessHealthData,
      agreedToTerms,
    } = formData;

    dispatch(
      signup(
        {
          firstName,
          lastName,
          email,
          sex,
          password,
          repeatPassword,
          birthDate: convertDate(formData.date),
          agreedToTerms,
          agreedToProcessHealthData,
          market: currentMarket.id,
        },
        successHandler
      )
    );
  };

  const convertDate = (date: string): string =>
    dayjs(new Date(date)).format("DD-MM-YYYY");

  const validateDateError = (submitCount: number, date?: string): void => {
    if (!date) {
      dispatch(
        setSignupErrorObject({
          birthDate: t("IncorrectDate"),
        })
      );
    } else if (
      date &&
      new Date(date) > dayjs().subtract(18, "year").toDate() &&
      submitCount > 0
    ) {
      dispatch(
        setSignupErrorObject({
          birthDate: t("OlderThan18"),
        })
      );
    } else if (
      date &&
      new Date(date) < dayjs().subtract(120, "year").toDate() &&
      submitCount > 0
    ) {
      dispatch(
        setSignupErrorObject({
          birthDate: t("IncorrectDate"),
        })
      );
    } else {
      dispatch(
        setSignupErrorObject({
          birthDate: "",
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setSignupErrorObject({}));
  }, [dispatch]);

  return (
    <div className={style.signupPageWrapper}>
      {signupLoading && <Spinner />}
      {success && (
        <SuccessMessage
          text={t("Signup.SuccessMessageText")}
          link="/login"
          buttonClickHandler={() => setSuccess(false)}
        />
      )}
      {showMain && (
        <>
          <span className={style.signupPageHeader}>{t("Signup.Title")}</span>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              sex: "",
              date: "",
              password: "",
              repeatPassword: "",
              agreedToTerms: false,
              agreedToProcessHealthData: false,
            }}
            onSubmit={(values) => {
              localSignup(values);
            }}
            validationSchema={RegistrationSchema}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
              submitCount,
            }) => {
              const birthdayError = errors.date || signupErrorsObject.birthDate;

              return (
                <div
                  className={style.formWrapper}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <div className={style.signupPageNamesWrapper}>
                    <CustomInput
                      contentContainerStyle="name"
                      text={t("Signup.FirstName")}
                      value={values.firstName}
                      autoComplete="temporarily-disabled"
                      error={errors.firstName || signupErrorsObject.firstName}
                      touched={touched.firstName}
                      changeHandler={handleChange("firstName")}
                    />
                    <CustomInput
                      contentContainerStyle="name"
                      text={t("Signup.LastName")}
                      value={values.lastName}
                      autoComplete="temporarily-disabled"
                      error={errors.lastName || signupErrorsObject.lastName}
                      touched={touched.lastName}
                      changeHandler={handleChange("lastName")}
                    />
                  </div>

                  <CustomInput
                    contentContainerStyle="email"
                    text={t("Signup.Email")}
                    value={values.email}
                    autoComplete="email"
                    error={errors.email || signupErrorsObject.email}
                    touched={touched.email}
                    changeHandler={handleChange("email")}
                  />
                  <div className={style.signupPageSexWrapper}>
                    <span className={style.signupPageInputText}>
                      {t("Signup.Sex")}
                    </span>
                    <div className={style.signupPageSexRadioWrapper}>
                      <Field
                        type="radio"
                        className={style.signupPageSexRadio}
                        id="female"
                        name="sex"
                        value="female"
                      />
                      <label
                        htmlFor="female"
                        className={style.signupPageSexLabel}
                      >
                        {t("Signup.Female")}
                      </label>
                      <Field
                        type="radio"
                        className={style.signupPageSexRadio}
                        id="male"
                        name="sex"
                        value="male"
                      />
                      <label
                        htmlFor="male"
                        className={style.signupPageSexLabel}
                      >
                        {t("Signup.Male")}
                      </label>
                    </div>
                    <div
                      className={
                        (touched.sex && errors.sex) || signupErrorsObject.sex
                          ? `${style.signupPageNameError} ${style.signupPageNameError__sex}`
                          : style.blockHidden
                      }
                    >
                      <div className={style.signupPageErrorText}>
                        {(errors.sex && t(errors.sex)) ||
                          signupErrorsObject.sex}
                      </div>
                    </div>
                  </div>
                  <CustomInput
                    contentContainerStyle="birthDay"
                    min={"1903-01-01"}
                    max={"2021-01-01"}
                    type="date"
                    text={t("Signup.BirthDate")}
                    value={values.date}
                    touched={touched.date}
                    changeHandler={handleChange("date")}
                    error={birthdayError}
                  />
                  <CustomInput
                    contentContainerStyle="password"
                    type="password"
                    text={t("Signup.Password")}
                    value={values.password}
                    autoComplete="current-password"
                    error={errors.password || signupErrorsObject.password}
                    touched={touched.password}
                    changeHandler={handleChange("password")}
                  />
                  <CustomInput
                    contentContainerStyle="password"
                    type="password"
                    text={t("Signup.RepeatPassword")}
                    value={values.repeatPassword}
                    autoComplete="repeat-current-password"
                    error={
                      errors.repeatPassword || signupErrorsObject.repeatPassword
                    }
                    touched={touched.repeatPassword}
                    changeHandler={handleChange("repeatPassword")}
                  />
                  <div
                    className={`${style.signupPageCheckboxWrapper} ${style.signupPageCheckboxWrapper__private}`}
                  >
                    <Field
                      type="checkbox"
                      className={style.signupPageCheckbox}
                      id="private policy"
                      name="agreedToTerms"
                    />
                    <label
                      className={style.signupPageCheckboxText}
                      htmlFor="private policy"
                    >
                      {addLinksToText(
                        consents.length > 0
                          ? consents.filter(
                              (consent) => consent.name === "agreedToTerms"
                            )[0].textT[currentLanguage]
                          : "",
                        style.signupPageCheckboxLink
                      )}
                    </label>
                  </div>
                  <div
                    className={`${style.signupPageCheckboxWrapper} ${style.signupPageCheckboxWrapper__data}`}
                  >
                    <Field
                      type="checkbox"
                      className={style.signupPageCheckbox}
                      id="isGatherPrivateData"
                      name="agreedToProcessHealthData"
                    />
                    <label
                      className={style.signupPageCheckboxText}
                      htmlFor="data processing"
                    >
                      {addLinksToText(
                        consents.length > 0
                          ? consents.filter(
                              (consent) =>
                                consent.name === "agreedToProcessHealthData"
                            )[0].textT[currentLanguage]
                          : "",
                        style.signupPageCheckboxLink
                      )}
                    </label>
                  </div>
                  <button
                    type="submit"
                    className={
                      values.agreedToProcessHealthData && values.agreedToTerms
                        ? `${style.signupPageButton} ${style.signupPageButton__enabled}`
                        : `${style.signupPageButton} ${style.signupPageButton__disabled}`
                    }
                    disabled={
                      !(
                        values.agreedToProcessHealthData && values.agreedToTerms
                      )
                    }
                    onClick={() => {
                      handleSubmit();
                      validateDateError(submitCount, values.date);
                    }}
                  >
                    {t("Signup.SingUpButton").toUpperCase()}
                  </button>
                </div>
              );
            }}
          </Formik>
          <div className={style.signupPageFooterWrapper}>
            <span className={style.signupPageFooterText}>
              {t("Signup.AlredyHaveAccount")}
            </span>
            <Link to="/login" className={style.signupPageFooterLink}>
              {t("Signup.LoginLink")}
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default SignupPage;
