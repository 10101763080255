import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Response.module.scss";

import {
  productVaccine,
  productWoman,
  productPerson,
  productCross,
} from "assets/image";
import { CollectionMethod } from "types";

type Props = {
  collectionMethod: CollectionMethod;
};

const Response = ({ collectionMethod }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.response}>
      {(collectionMethod === CollectionMethod.FingerPrickBlood ||
        collectionMethod === CollectionMethod.UrineSample) && (
        <div className={styles.fromBlood}>
          <div className={styles.response__content}>
            <div className={styles.response__textContent}>
              <div className={styles.response__textContent__items}>
                <div className={styles.response__text}>
                  {t("Product.Response.FingerPrickBlood.Text0")}
                </div>
                <div className={styles.response__text}>
                  {t("Product.Response.FingerPrickBlood.Text1")}
                </div>
              </div>
            </div>
            <img
              className={styles.response__image}
              src={productWoman}
              alt="Woman"
            />
          </div>
        </div>
      )}
      {collectionMethod === CollectionMethod.SalivaSample && (
        <div className={styles.fromSaliva}>
          <div className={styles.response__title}>
            {t("Product.Response.SalivaSample.Title")}
          </div>
          <div className={styles.response__subtitle}>
            {t("Product.Response.SalivaSample.Subtitle")}
          </div>
          <div className={styles.response__content}>
            <div className={styles.response__textContent}>
              <div className={styles.response__text}>
                {t("Product.Response.SalivaSample.Text0")}
              </div>
              <div className={styles.response__text}>
                {t("Product.Response.SalivaSample.Text1")}
              </div>
              <div className={styles.response__listTitle}>
                {t("Product.Response.SalivaSample.ListTitle")}
              </div>
              <ul className={styles.response__list}>
                <li className={styles.response__listItem}>
                  {t("Product.Response.SalivaSample.ListItem0")}
                </li>
                <li className={styles.response__listItem}>
                  {t("Product.Response.SalivaSample.ListItem1")}
                </li>
                <li className={styles.response__listItem}>
                  {t("Product.Response.SalivaSample.ListItem2")}
                </li>
                <li className={styles.response__listItem}>
                  {t("Product.Response.SalivaSample.ListItem3")}
                </li>
              </ul>
            </div>
            <div className={styles.response__imageContent}>
              <img
                className={styles.response__image}
                src={productVaccine}
                alt="Vaccine"
              />
              <div className={styles.message}>
                <div className={styles.message__input}>
                  <img
                    className={styles.message__presonIcon}
                    src={productPerson}
                    alt="Cross icon"
                  />
                  <div className={styles.message__email}>
                    {t("Product.Response.SalivaSample.EmailAddress")}
                  </div>
                </div>
                <div className={styles.message__result}>
                  <img
                    className={styles.message__crossIcon}
                    src={productCross}
                    alt="Cross icon"
                  />
                  <div className={styles.message__textContent}>
                    <div
                      className={`${styles.message__text} ${styles.message__text_bold}`}
                    >
                      {t("Product.Response.SalivaSample.EmailHeader")}
                    </div>
                    <div className={styles.message__text}>
                      {t("Product.Response.SalivaSample.EmailText")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Response;
