import * as React from "react";
import { Link } from "react-router-dom";
import {
  useElements,
  useStripe,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Formik } from "formik";

import styles from "./Order.module.scss";

import { useTypedSelector, useAction, useAppDispatch } from "hooks";
import { setCart, getDepartmentsList, addChild } from "store/thunks";
import ResizableSpinner from "components/ResizableSpinner";
import config from "config";
import {
  PaymentSystem,
  PaymentInformationDTO,
  PaymentType,
  InsuranceCompany,
  OpayPaymentChannel,
  PaymentDTO,
  CartItemType,
  TestPackageType,
  Consent,
} from "types";
import {
  fetchTestPackages,
  setActivePromoCode,
  setNeedCalendlyVerification,
} from "store/actions";
import analyticsService from "services/analyticsService";
import { LanguageContext } from "context";
import UserPersonalDataBlock from "components/Checkout/UserPersonalDataBlock";
import ChildPersonalDataBlock from "components/Checkout/ChildPersonalDataBlock";
import DeliveryInformationBlock from "components/Checkout/DeliveryInformationBlock";
import PaymentBlock from "components/Checkout/PaymentBlock";
import ConsentsBlock from "components/Checkout/ConsentsBlock";
import {
  mobileNumberValidator,
  getOrderValidationSchema,
  validateDateError,
} from "validation";
import { getDeliveryInformation } from "helpers";

const getRequiredConsents = (
  cart: CartItemType[],
  testPackages: TestPackageType[],
  consents: Consent[]
): Consent[] => {
  const requiredConsentsArray: string[] = [];

  cart.some((cartItem) =>
    testPackages
      .find(
        (testPackage) => testPackage.packageLabId === cartItem.testPackageLabId
      )
      ?.consents.forEach((consent) => requiredConsentsArray.push(consent))
  );

  const uniqueConsentsId = Array.from(new Set(requiredConsentsArray));

  return consents.filter((consent) =>
    uniqueConsentsId.some((id) => consent.id === id)
  );
};

const UserInfo = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { consents } = useTypedSelector((state) => state.consents);
  const { user, userLoading } = useTypedSelector((state) => state.user);
  const { cart } = useTypedSelector((state) => state.cart);
  const { activePromoCode } = useTypedSelector((state) => state.promoCodes);
  const { createOrderLoading } = useTypedSelector((state) => state.orders);
  const { consentsLoading } = useTypedSelector((state) => state.consents);
  const { departmentsLoading, departmentsList, departmentsError } =
    useTypedSelector((state) => state.omnivaDepartments);
  const { isLoadingTestPackages, items, fetchTestPackagesError } =
    useTypedSelector((state) => state.testPackages);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] =
    React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { createOrder } = useAction();
  const { currentLanguage } = React.useContext(LanguageContext);

  // User personal data block
  const requiresIdentificationCode = cart.some(
    (cartItem) =>
      items.find(
        (testPackage) => testPackage.packageLabId === cartItem.testPackageLabId
      )?.requiresIdentificationCode
  );
  const [isChildOrder, setIsChildOrder] = React.useState(false);

  // Child personal data block
  const [dateError, setDateError] = React.useState("");

  // Payment block
  const [insuranceNumber, setInsuranceNumber] = React.useState("");
  const [insuranceNumberTouched, setInsuranceNumberTouched] =
    React.useState(false);
  const [privateCodeTouched, setPrivateCodeTouched] = React.useState(false);
  const [privateCode, setPrivateCode] = React.useState("");
  const [selectedPaymentSystem, setSelectedPaymentSystem] =
    React.useState<PaymentSystem>(
      cart.some((test) => test.paymentType === PaymentType.OneTime) &&
        currentMarket.config.paymentSystems.includes(PaymentSystem.Opay)
        ? PaymentSystem.Opay
        : PaymentSystem.Stripe
    );
  const [activeBank, setActiveBank] = React.useState(
    OpayPaymentChannel.swedbank
  );
  const [activeInsuranceCompany, setActiveInsuranceCompany] = React.useState(
    InsuranceCompany.GjensidigeInsurance
  );

  // Consents block
  const requiredConsents = getRequiredConsents(cart, items, consents);
  const [stripeFormError, setStripeFormError] = React.useState("");
  const [pageConsents, setPageConsents] = React.useState<
    Record<string, boolean>
  >({});

  const convertDate = (year: string, month: string, day: string): string =>
    dayjs(new Date(+year, +month - 1, +day)).format("DD-MM-YYYY");

  const pay = async (formData: PaymentDTO) => {
    let paymentInformation = {} as PaymentInformationDTO;
    const deliveryInformation = getDeliveryInformation(
      formData,
      currentMarket,
      requiresIdentificationCode
    );

    setStripeFormError("");

    if (!stripe || !elements) {
      return;
    }

    if (selectedPaymentSystem === PaymentSystem.Stripe) {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement) || { token: "" },
      });

      if (error) {
        setStripeFormError(error.message || "");
      }

      if (!paymentMethod || error) {
        return;
      }

      paymentInformation = {
        paymentMethodId: paymentMethod.id,
      };
    } else if (selectedPaymentSystem === PaymentSystem.Opay) {
      paymentInformation = {
        userEmail: user.email,
        paymentChannel: activeBank,
      };
    } else if (selectedPaymentSystem === PaymentSystem.Insurance) {
      paymentInformation = {
        insuranceNumber: insuranceNumber,
        identificationCode: privateCode,
        companyName: activeInsuranceCompany,
      };
    }

    const child = isChildOrder
      ? await dispatch(
          addChild({
            firstName: formData.childFirstName,
            lastName: formData.childLastName,
            sex: formData.childSex,
            birthDate: convertDate(
              formData.childYear,
              formData.childMonth,
              formData.childDay
            ),
            identificationCode: formData.childIdentificationCode,
          })
        ).unwrap()
      : undefined;

    // @ts-ignore
    const { isSuccess, opayEncodedString } = await createOrder(
      paymentInformation,
      currentMarket.config.deliverySystems[0],
      deliveryInformation,
      activePromoCode?.id,
      selectedPaymentSystem,
      isChildOrder ? child?.id : undefined,
      pageConsents,
      stripe
    );

    if (selectedPaymentSystem === PaymentSystem.Opay) {
      window.location.href = config.opayBaseUrl + opayEncodedString;
    }

    if (isSuccess) {
      const value =
        cart.reduce((sum, item) => sum + item.price * item.quantity, 0) / 100;
      analyticsService.createGAEvent("purchase", {
        value,
        items: cart.map((cartItem) => ({
          item_id: cartItem.testPackageId,
          item_name: cartItem.testPackageName,
          price: cartItem.price / 100,
          quantity: cartItem.quantity,
        })),
      });
      //@ts-ignore
      const gtag = window.gtag;
      if (gtag && config.appEnvironment === "production") {
        gtag("event", "conversion", {
          send_to: "AW-329477345/VskgCL3ouYsDEOHZjZ0B",
          value,
          currency: "EUR",
        });
      }
      analyticsService.createFacebookEvent("default", "Purchase", {
        value: value.toString(),
        currency: "EUR",
        contents: cart.map((cartItem) => ({
          id: cartItem.testPackageId,
          name: cartItem.testPackageName,
          price: cartItem.price / 100,
          quantity: cartItem.quantity,
        })),
      });
      dispatch(
        setNeedCalendlyVerification(
          cart
            .map((cartItem) =>
              config.calendlyVerifyingTestLabId.some(
                (id) => id === cartItem.testPackageLabId
              )
            )
            .some((isCalendlyVerifying) => isCalendlyVerifying)
        )
      );
      dispatch(setCart([]));
      dispatch(setActivePromoCode(null));
    }
  };

  React.useEffect(() => {
    setIsSubmitButtonEnabled(
      !(
        Object.keys(pageConsents).length !== requiredConsents.length ||
        requiredConsents.some((el) =>
          Object.entries(pageConsents).find(
            ([value, boolean]) => el.name === value && !boolean
          )
        )
      )
    );
  }, [pageConsents, requiredConsents]);

  React.useEffect(() => {
    if (
      !isLoadingTestPackages &&
      !items &&
      !fetchTestPackagesError &&
      Object.keys(currentMarket).length !== 0
    ) {
      dispatch(fetchTestPackages(currentLanguage, currentMarket.id));
    }
  }, [
    dispatch,
    isLoadingTestPackages,
    fetchTestPackagesError,
    currentLanguage,
    items,
    currentMarket,
  ]);

  React.useEffect(() => {
    if (
      !departmentsLoading &&
      departmentsList.length < 1 &&
      !departmentsError
    ) {
      dispatch(getDepartmentsList());
    }
  }, [departmentsError, departmentsList.length, departmentsLoading, dispatch]);

  if (createOrderLoading || userLoading || consentsLoading) {
    return (
      <div className={styles.wrapper}>
        <ResizableSpinner />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.order}>
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            birthDateYourself: String(user.birthDate),
            mobileNumber: mobileNumberValidator(user.mobileNumber)
              ? user.mobileNumber
              : currentMarket.config.mobileCountryCode,
            email: user.email,
            identificationCode: "",
            address: "",
            name: "",
            county: "",
            postalCode: "",
            childFirstName: "",
            childLastName: "",
            birthChild: "",
            childYear: "",
            childMonth: "",
            childDay: "",
            childIdentificationCode: "",
            childSex: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
          }}
          onSubmit={(values) => {
            if (selectedPaymentSystem === PaymentSystem.Insurance) {
              setInsuranceNumberTouched(true);
              setPrivateCodeTouched(true);
              if (insuranceNumber.length < 4 || !privateCode) {
                return;
              }
            }

            if (isChildOrder) {
              setDateError(
                t(
                  validateDateError(
                    null,
                    values.childYear,
                    values.childMonth,
                    values.childDay
                  )
                )
              );
            }

            if (!Object.values(pageConsents).every((consent) => consent)) {
              return;
            }

            pay(values);

            if (selectedPaymentSystem !== PaymentSystem.Opay) {
              Object.fromEntries(
                Object.keys(pageConsents).map((key) => [key, false])
              );
            }
          }}
          validationSchema={getOrderValidationSchema(
            requiresIdentificationCode,
            isChildOrder,
            currentMarket.config.deliverySystems[0]
          )}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            setValues,
            submitCount,
          }) => {
            const formDataAndFunctions = {
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              setValues,
              submitCount,
            };
            return (
              <div
                className={styles.formWrapper}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <UserPersonalDataBlock
                  formDataAndFunctions={formDataAndFunctions}
                  requiresIdentificationCode={requiresIdentificationCode}
                  isChildOrder={isChildOrder}
                  setIsChildOrder={setIsChildOrder}
                />
                {isChildOrder && (
                  <ChildPersonalDataBlock
                    formDataAndFunctions={formDataAndFunctions}
                    dateError={dateError}
                  />
                )}
                <DeliveryInformationBlock
                  formDataAndFunctions={formDataAndFunctions}
                />
                <PaymentBlock
                  insuranceNumber={insuranceNumber}
                  insuranceNumberTouched={insuranceNumberTouched}
                  privateCodeTouched={privateCodeTouched}
                  privateCode={privateCode}
                  selectedPaymentSystem={selectedPaymentSystem}
                  activeInsuranceCompany={activeInsuranceCompany}
                  activeBank={activeBank}
                  setInsuranceNumber={setInsuranceNumber}
                  setPrivateCode={setPrivateCode}
                  setSelectedPaymentSystem={setSelectedPaymentSystem}
                  setActiveBank={setActiveBank}
                  setActiveInsuranceCompany={setActiveInsuranceCompany}
                />
                <ConsentsBlock
                  stripeFormError={stripeFormError}
                  pageConsents={pageConsents}
                  setPageConsents={setPageConsents}
                  requiredConsents={requiredConsents}
                />
                <div className={styles.order__payButtonBlock}>
                  <Link
                    className={
                      isSubmitButtonEnabled
                        ? styles.order__payButton
                        : `${styles.order__payButton} ${styles.order__payButton_disabled}`
                    }
                    to={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    {t("Checkout.UserInfo.ButtonPay")}
                  </Link>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default UserInfo;
