import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { withTranslation, useTranslation } from "react-i18next";

import style from "./HeaderMenuMobile.module.scss";

import { useAction, useTypedSelector } from "hooks";
import { ShowMenusType } from "components/Header";
import {
  headerArrowDown,
  headerArrowUp,
  covidTest,
  womanHealthTest,
  manHealthTest,
  wellnessTest,
} from "assets/image";
import config from "config";

type Props = {
  showMenus: ShowMenusType;
  setShowMenus: React.Dispatch<React.SetStateAction<ShowMenusType>>;
};

function HeaderMenuMobile({ showMenus, setShowMenus }: Props): JSX.Element {
  const { t } = useTranslation();

  const { isAuthorized } = useTypedSelector((state) => state.auth);

  const {
    setIsOpenCovidTestPackage,
    setIsOpenManHealthTestPackage,
    setIsOpenWomanHealthTestPackage,
    setIsOpenWellnessTestPackage,
  } = useAction();

  return (
    <>
      {!isAuthorized && !showMenus.mobileMenu && (
        <div className={style.buttonWrapper}>
          <Link
            className={style.registerItem}
            to="/signup"
            onClick={() => setShowMenus({ ...showMenus, mobileMenu: false })}
          >
            {t("Header.ButtonRegister").toUpperCase()}
          </Link>
          <Link
            className={style.joinItem}
            to="/login"
            onClick={() => setShowMenus({ ...showMenus, mobileMenu: false })}
          >
            {t("Header.ButtonLogin").toUpperCase()}
          </Link>
        </div>
      )}
      {showMenus.mobileMenu && (
        <div className={style.wrapper}>
          <div className={style.testsWrapper}>
            <div
              className={style.testItem}
              onClick={() =>
                setShowMenus({
                  ...showMenus,
                  mobileTestsMenu: !showMenus.mobileTestsMenu,
                })
              }
            >
              {t("TestPackages.Header")}
              <img
                src={
                  showMenus.mobileTestsMenu ? headerArrowUp : headerArrowDown
                }
                alt="burger"
              />
            </div>
            {showMenus.mobileTestsMenu && (
              <div className={style.testsMenuWrapper}>
                <Link
                  className={style.testsMenuItem}
                  to="/#covid19"
                  onClick={() => setIsOpenCovidTestPackage(true)}
                >
                  <img
                    className={style.testMenuImage}
                    src={covidTest}
                    alt="covid"
                  />
                  {t("TestPackages.Covid19")}
                </Link>
                <Link
                  className={style.testsMenuItem}
                  to="/#womanHealth"
                  onClick={() => setIsOpenWomanHealthTestPackage(true)}
                >
                  <img
                    className={style.testMenuImage}
                    src={womanHealthTest}
                    alt="woman health"
                  />
                  {t("TestPackages.WomensHealth")}
                </Link>
                <Link
                  className={style.testsMenuItem}
                  to="/#manHealth"
                  onClick={() => setIsOpenManHealthTestPackage(true)}
                >
                  <img
                    className={style.testMenuImage}
                    src={manHealthTest}
                    alt="man health"
                  />
                  {t("TestPackages.MensHealth")}
                </Link>
                <Link
                  className={style.testsMenuItem}
                  to="/#wellness"
                  onClick={() => setIsOpenWellnessTestPackage(true)}
                >
                  <img
                    className={style.testMenuImage}
                    src={wellnessTest}
                    alt="wellness"
                  />
                  {t("TestPackages.Wellness")}
                </Link>
              </div>
            )}
          </div>
          <Link className={style.menuItem} to="/#howdoesitworks">
            {t("Header.HowItWorks")}
          </Link>
          <Link
            className={style.menuItem}
            to={`/survey/${config.burnoutAliasId}`}
          >
            {t("Header.Surveys")}
          </Link>
          {isAuthorized && (
            <Link className={style.menuItem} to="/dashboard">
              {t("Header.TestsResults")}
            </Link>
          )}
        </div>
      )}
    </>
  );
}

export default withTranslation()(HeaderMenuMobile);
