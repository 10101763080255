import React from "react";
import { useTranslation } from "react-i18next";

import style from "./TestResultNumeric.module.scss";

import {
  ChartItemType,
  ColorValues,
  NormStatus,
  ScaleItemType,
  TestResultItemType,
} from "types";
import { testNotOk, testOk } from "assets/image";

type Props = {
  item: TestResultItemType;
};

export default function TestResultNumeric({ item }: Props): JSX.Element {
  const { t } = useTranslation();
  const currentResult = item.chart.find((res: ChartItemType) => res.current);
  const prevResult = item.chart.find((res: ChartItemType) => res.previous);

  const calculatePercentWidth = (range: ScaleItemType) =>
    ((range.max - range.min) * 100) / item.scale[item.scale.length - 1].max;

  const isMarked = (range: ScaleItemType) =>
    currentResult &&
    currentResult.result >= range.min &&
    currentResult.result <= range.max;

  return (
    <div className={style.wrapper}>
      <div className={style.header}>{item.testName}</div>
      {item.status === NormStatus.notCompleted ? (
        <div className={`${style.pannel} ${style.pannel__testFailed}`}>
          {t("Result.Failed")}
        </div>
      ) : (
        <>
          <div
            className={`${style.pannel} ${
              item.status === NormStatus.normal
                ? style.pannel__testNegative
                : style.pannel__testPositive
            }`}
          >
            <img
              src={item.status === NormStatus.normal ? testOk : testNotOk}
              alt="test result"
              className={style.pannel__image}
            ></img>
            <span className={style.pannel__text}>
              {t(`Result.${item.status}`)}
            </span>
            <span className={style.pannel__value}>{`${
              currentResult ? currentResult.result : ""
            } ${item.dimension}`}</span>
          </div>
          <div className={style.colorAxisWrapper}>
            <div className={style.colorsRanges}>
              {item.scale.map((range: ScaleItemType, index: number) => {
                return (
                  <div
                    key={`${range.min} ${range.status} ${range.max}`}
                    className={style.colorsRanges__item}
                    style={{ width: `${calculatePercentWidth(range) - 1}%` }}
                  >
                    <div
                      className={style.colorsRanges__rect}
                      style={{ background: ColorValues[range.color] }}
                    >
                      <div
                        className={`${
                          isMarked(range) &&
                          style[`colorsRanges__mark__${range.color}`]
                        }`}
                        style={{
                          marginLeft: isMarked(range)
                            ? `${
                                (((currentResult?.result ?? 0) - range.min) *
                                  100) /
                                (range.max - range.min)
                              }%`
                            : 0,
                        }}
                      />
                    </div>
                    <div className={style.colorsRanges__values}>
                      <div>{index === 0 && "0"}</div>
                      <div>{range.max}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      <div className={style.footer}>
        {t("Result.Previous")}
        <span className={style.footer__result}>
          {prevResult ? prevResult.result : t("Result.NotStudied")}
        </span>
      </div>
    </div>
  );
}
