import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import style from "./Dashboard.module.scss";

import PackageItem from "components/Dashboard/PackageItem";
import Spinner from "components/Spinner";
import UploadPopup from "components/Dashboard/UploadPopup";
import { useAction, useAppDispatch, useTypedSelector } from "hooks";
import { ChildResultItemType, ResultItemType } from "types";
import { blueFolder } from "assets/image";
import { fetchAllUserResults, fetchCustomResults } from "store/actions";
import { getChildren, getChildResults } from "store/thunks";

function DashboardPage(): JSX.Element {
  const {
    setIsOpenCovidTestPackage,
    setIsOpenManHealthTestPackage,
    setIsOpenWomanHealthTestPackage,
    setIsOpenWellnessTestPackage,
  } = useAction();
  const {
    allUserResultsLoading,
    allUserResults,
    customResultsLoading,
    customResults,
  } = useTypedSelector((state) => state.results);
  const { authLoading } = useTypedSelector((state) => state.auth);
  const { user, userLoading } = useTypedSelector((state) => state.user);
  const { isChildLoading, children, isChildResultsLoading, childrenResults } =
    useTypedSelector((state) => state.child);
  const dispatch = useAppDispatch();
  const allResults = [
    ...allUserResults.map((result) => ({ ...result, isCustom: false })),
    ...customResults.map((result) => ({ ...result, isCustom: true })),
  ].sort((a, b) => {
    if (a.date < b.date) return 1;
    if (a.date > b.date) return -1;
    return 0;
  });
  const [uploadPopupVisible, setUploadPopupVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchAllUserResults());
    dispatch(fetchCustomResults());
    dispatch(getChildren());
  }, [dispatch]);

  useEffect(() => {
    if (!isChildLoading && children) {
      children.forEach((child) => {
        dispatch(getChildResults(child.id));
      });
    }
  }, [dispatch, isChildLoading, children]);

  if (
    allUserResultsLoading ||
    authLoading ||
    userLoading ||
    customResultsLoading ||
    isChildLoading ||
    isChildResultsLoading
  ) {
    return (
      <div className={style.wrapper}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={style.wrapper}>
      {uploadPopupVisible && (
        <UploadPopup close={() => setUploadPopupVisible(false)} />
      )}
      {allResults.length > 0 && (
        <div className={style.container}>
          <div className={style.header}>
            {user.firstName ? `${t("Dashboard.Title")}${user.firstName}` : ""}
          </div>
          <div className={style.titleHolder}>
            <div className={style.text}>{t("Dashboard.Subtitle")}</div>
          </div>
          <div className={style.packageListWrapper}>
            {allResults.map((item: ResultItemType) => (
              <PackageItem
                key={`${item.id}${item.testPackage}`}
                testPackage={item.testPackage}
                resultDate={item.date}
                resultId={item.id}
              />
            ))}
          </div>
        </div>
      )}
      {children && children.length > 0 && (
        <div className={style.childContainer}>
          {children.map((child) => {
            return (
              childrenResults[child.id] &&
              childrenResults[child.id].length > 0 && (
                <div className={style.container} key={child.id}>
                  <div className={style.titleHolder}>
                    <div className={style.text}>{`${child.firstName} ${
                      child.lastName
                    } ${t("Dashboard.ChildSubtitle")}`}</div>
                  </div>
                  <div className={style.packageListWrapper}>
                    {childrenResults[child.id] &&
                      childrenResults[child.id].map(
                        (item: ChildResultItemType) => (
                          <PackageItem
                            key={item.id}
                            testPackage={item.testPackage}
                            resultDate={item.date}
                            resultId={item.id}
                          />
                        )
                      )}
                  </div>
                </div>
              )
            );
          })}
        </div>
      )}
      {allResults.length < 1 && (
        <div className={style.emptyResultsContainer}>
          <img
            className={style.emptyResultsContainer__image}
            src={blueFolder}
            alt="blue folder"
          />
          <div className={style.emptyResultsContainer__header}>
            {t("Dashboard.NoResults")}
          </div>
          <div className={style.emptyResultsContainer__text}>
            {t("Dashboard.OrderText")}
          </div>
          <Link
            className={style.emptyResultsContainer__link}
            to="/#tyrimai"
            onClick={() => {
              setIsOpenCovidTestPackage(true);
              setIsOpenManHealthTestPackage(true);
              setIsOpenWomanHealthTestPackage(true);
              setIsOpenWellnessTestPackage(true);
            }}
          >
            {t("Dashboard.ButtonOrder")}
          </Link>
        </div>
      )}
    </div>
  );
}

export default DashboardPage;
