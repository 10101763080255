import React, { useState, useEffect, useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";

import style from "./Header.module.scss";

import i18next from "internationalization";
import { useAction, useTypedSelector, useAppDispatch } from "hooks";
import { setIsOpenCartPopup } from "store/slices";
import { getCart, getUser } from "store/thunks";
import CartPopup from "components/Header/CartPopup";
import HeaderMenuMobile from "components/Header/HeaderMenuMobile";
import { useMediaQueryContext } from "components/MediaQueryProvider";
import {
  covidTest,
  womanHealthTest,
  manHealthTest,
  wellnessTest,
  cartImage,
  headerBurger,
  headerCross,
  userIcon,
  selectArrow,
  selectArrowUp,
  contactsGrey,
  settingsGrey,
  ordersGrey,
  headerLogo,
} from "assets/image";
import {
  fetchAllUserResults,
  fetchCustomResults,
  fetchResult,
  translateTestPackages,
} from "store/actions";
import { Language } from "types";
import httpService from "services/httpService";
import { LanguageContext } from "context";
import config from "config";

export type LanguageItemType = {
  shortName: Language;
  flag: string;
  name: string;
};

export type ShowMenusType = {
  mobileMenu: boolean;
  testsPopup: boolean;
  mobileTestsMenu: boolean;
  userMenu: boolean;
  languageMenu: boolean;
};

function Header(): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useMediaQueryContext();
  const [showMenus, setShowMenus] = useState({
    mobileMenu: false,
    testsPopup: false,
    mobileTestsMenu: false,
    userMenu: false,
    languageMenu: false,
  });
  const {
    setIsOpenCovidTestPackage,
    setIsOpenManHealthTestPackage,
    setIsOpenWomanHealthTestPackage,
    setIsOpenWellnessTestPackage,
  } = useAction();
  const dispatch = useAppDispatch();
  const { isAuthorized } = useTypedSelector((state) => state.auth);
  const { cart, isOpenCartPopup } = useTypedSelector((state) => state.cart);
  const { user } = useTypedSelector((state) => state.user);
  const location = useLocation();
  const { setCurrentLanguage } = useContext(LanguageContext);
  const [visible, setVisible] = useState(true);

  const languageItemClickHandler = (currentLanguage: Language): void => {
    i18next.changeLanguage(currentLanguage);
    localStorage.setItem("language", JSON.stringify(i18next.language));
    httpService.setLanguageHeader(currentLanguage);
    setShowMenus({
      ...showMenus,
      languageMenu: false,
    });
    dispatch(translateTestPackages(currentLanguage));
    if (isAuthorized && Object.keys(user).length > 0) {
      dispatch(getCart());
    }
    if (location.pathname === "/dashboard") {
      dispatch(fetchAllUserResults());
      dispatch(fetchCustomResults());
    }
    if (location.pathname.includes("/result/")) {
      const resultId = location.pathname.replace("/result/", "");
      dispatch(fetchResult(resultId));
    }

    setCurrentLanguage(currentLanguage);
  };

  useEffect(() => {
    setShowMenus({
      mobileMenu: false,
      testsPopup: false,
      mobileTestsMenu: false,
      userMenu: false,
      languageMenu: false,
    });
    dispatch(setIsOpenCartPopup(false));
    window.scrollTo(0, 0);
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(getUser());
    }
  }, [isAuthorized, dispatch]);

  useEffect(() => {
    if (isAuthorized && Object.keys(user).length > 0) {
      dispatch(getCart());
    }
  }, [isAuthorized, dispatch, user]);

  useEffect(() => {
    if (location.pathname === "/forbusiness") {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [location]);

  return (
    <div
      className={
        visible ? style.mainWrapper : `${style.mainWrapper} ${style.hidden}`
      }
      id="header"
    >
      <div className={style.wrapper}>
        <Link className={style.logoWrapper} to="/">
          <img src={headerLogo} alt="header logo" />
        </Link>
        {!isMobile && (
          <>
            <div
              className={style.testItem}
              onClick={() =>
                setShowMenus({
                  ...showMenus,
                  testsPopup: !showMenus.testsPopup,
                })
              }
            >
              {t("TestPackages.Header")}
              {showMenus.testsPopup && (
                <div className={style.testPackagesPopup}>
                  <Link
                    className={style.testPackageItem}
                    to="/#covid19"
                    onClick={() => setIsOpenCovidTestPackage(true)}
                  >
                    <img
                      className={style.testPackageItem__image}
                      src={covidTest}
                      alt="covid"
                    />
                    {t("TestPackages.Covid19")}
                  </Link>
                  <Link
                    className={style.testPackageItem}
                    to="/#womanHealth"
                    onClick={() => setIsOpenWomanHealthTestPackage(true)}
                  >
                    <img
                      className={style.testPackageItem__image}
                      src={womanHealthTest}
                      alt="woman health"
                    />
                    {t("TestPackages.WomensHealth")}
                  </Link>
                  <Link
                    className={style.testPackageItem}
                    to="/#manHealth"
                    onClick={() => setIsOpenManHealthTestPackage(true)}
                  >
                    <img
                      className={style.testPackageItem__image}
                      src={manHealthTest}
                      alt="man health"
                    />
                    {t("TestPackages.MensHealth")}
                  </Link>
                  <Link
                    className={style.testPackageItem}
                    to="/#wellness"
                    onClick={() => setIsOpenWellnessTestPackage(true)}
                  >
                    <img
                      className={style.testPackageItem__image}
                      src={wellnessTest}
                      alt="wellness"
                    />
                    {t("TestPackages.Wellness")}
                  </Link>
                </div>
              )}
            </div>
            <Link className={style.howDoesItWorksItem} to="/#howdoesitworks">
              {t("Header.HowItWorks")}
            </Link>
          </>
        )}
        {!isMobile && (
          <Link
            className={style.testResultsItem}
            to={`/survey/${config.burnoutAliasId}`}
          >
            {t("Header.Surveys")}
          </Link>
        )}
        {isAuthorized && !isMobile && (
          <Link className={style.testResultsItem} to="/dashboard">
            {t("Header.TestsResults")}
          </Link>
        )}
        {isAuthorized && (
          <div className={style.userItem}>
            <img
              className={style.userItem__image}
              src={userIcon}
              alt="user"
              onClick={() =>
                setShowMenus({ ...showMenus, userMenu: !showMenus.userMenu })
              }
            ></img>
            {showMenus.userMenu && (
              <div className={style.userMenuPopup}>
                <Link
                  className={style.userMenuPopupItem}
                  to="/user/profile/orders"
                >
                  <img
                    className={style.userMenuPopupItem__image}
                    src={ordersGrey}
                    alt="orders"
                  />
                  {t("Header.OrderList")}
                </Link>
                <Link
                  className={style.userMenuPopupItem}
                  to="/user/profile/settings"
                >
                  <img
                    className={style.userMenuPopupItem__image}
                    src={settingsGrey}
                    alt="settings"
                  />
                  {t("Header.Settings")}
                </Link>
                <Link
                  className={style.userMenuPopupItem}
                  to="/user/profile/contacts"
                >
                  <img
                    className={style.userMenuPopupItem__image}
                    src={contactsGrey}
                    alt="contacts"
                  />
                  {t("Header.Contacts")}
                </Link>
              </div>
            )}
          </div>
        )}
        {isAuthorized && !isMobile && (
          <div
            className={style.userItem__pointerBlock}
            onClick={() =>
              setShowMenus({ ...showMenus, userMenu: !showMenus.userMenu })
            }
          >
            <div className={style.userItem__text}>{`${
              user.firstName ? user.firstName : ""
            } ${user.lastName ? user.lastName : ""}`}</div>
            <img
              className={style.userItem__select}
              src={showMenus.userMenu ? selectArrowUp : selectArrow}
              alt="select"
            ></img>
          </div>
        )}
        {!isAuthorized && !isMobile && (
          <>
            <Link className={style.registerItem} to="/signup">
              {t("Header.ButtonRegister").toUpperCase()}
            </Link>
            <Link className={style.joinItem} to="/login">
              {t("Header.ButtonLogin").toUpperCase()}
            </Link>
          </>
        )}
        <div
          className={style.cartItemWrapper}
          onClick={() => {
            if (cart.length > 0) {
              dispatch(setIsOpenCartPopup(!isOpenCartPopup));
            }
          }}
        >
          {cart.length > 0 && (
            <div className={style.cartItemQuantity}>{cart.length}</div>
          )}
          <img src={cartImage} className={style.cartItemImage} alt="cart" />
          {isOpenCartPopup && <CartPopup />}
        </div>
        {isMobile && (
          <button
            className={style.burgerItem}
            onClick={() =>
              setShowMenus({ ...showMenus, mobileMenu: !showMenus.mobileMenu })
            }
          >
            <img
              src={showMenus.mobileMenu ? headerCross : headerBurger}
              alt="burger"
            />
          </button>
        )}
      </div>
      {isMobile && (
        <HeaderMenuMobile showMenus={showMenus} setShowMenus={setShowMenus} />
      )}
    </div>
  );
}

export default withTranslation()(Header);
