import React, { FC } from "react";
import styles from "./HowItWorks.module.scss";
import { useTranslation } from "react-i18next";
import { orderAStudy, geolocation, resultsOnline } from "assets/image";

const HowItWorks: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{t("Product.HowItWork.Title")}</span>
      </div>
      <div className={styles.list}>
        <div className={styles.list__item}>
          <div>
            <img src={orderAStudy} alt={"orderAStudy"} />
            <div className={styles.list__item__title}>
              {t("Product.HowItWork.OrderAStudyTitle")}
            </div>
            <div className={styles.list__item__subtitle}>
              {t("Product.HowItWork.OrderAStudySubtitle")}
            </div>
          </div>
        </div>
        <div className={styles.list__item}>
          <div>
            <img src={geolocation} alt={"geolocation"} />
            <div className={styles.list__item__title}>
              {t("Product.HowItWork.GeolocationTitle")}
            </div>
            <div className={styles.list__item__subtitle}>
              {t("Product.HowItWork.GeolocationSubtitle")}
            </div>
          </div>
        </div>
        <div className={styles.list__item}>
          <div>
            <img src={resultsOnline} alt={"resultsOnline"} />
            <div className={styles.list__item__title}>
              {t("Product.HowItWork.ResultsOnlineTitle")}
            </div>
            <div className={styles.list__item__subtitle}>
              {t("Product.HowItWork.ResultsOnlineSubtitle")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
