import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./DeliveryInformationBlock.module.scss";
import CustomSelect from "components/CustomSelect";
import { removeDuplicates } from "helpers";
import { getDepartmentsList } from "store/thunks/omnivaDepartments";
import { useAppDispatch, useTypedSelector } from "hooks";
import {
  OmnivaDepartment,
  SelectItemType,
  CheckoutFormDataAndFunctions,
  Country,
} from "types";
import CustomInput from "components/CustomInput";
import UsStatesDropdown from "components/Checkout/DeliveryInformationBlock/UsStatesDropdown";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { geolocationPayment, timePayment } from "../../../assets/image";
import { getHours } from "../../../helpers/generateHours";

type Props = {
  formDataAndFunctions: CheckoutFormDataAndFunctions;
};

const DeliveryInformationBlock = ({
  formDataAndFunctions,
}: Props): JSX.Element => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
  } = formDataAndFunctions;
  const { departmentsLoading, departmentsList, departmentsError } =
    useTypedSelector((state) => state.omnivaDepartments);
  const countySelectOptions = removeDuplicates(
    departmentsList.map((department) => department.county).sort()
  ).map((county) => ({ label: county, value: county }));
  const { createOrderErrorObject } = useTypedSelector((state) => state.orders);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const [selected, setSelected] = React.useState<Date>();
  const [selectedTime, setSelectedTime] = React.useState<string>(getHours()[0]);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const parcelMachineFields: Array<keyof OmnivaDepartment> = [
    "address",
    "name",
    "postalCode",
  ];
  const parcelMachineSelectOptions = React.useMemo(
    () =>
      departmentsList
        .filter((department) => department.county === values.county)
        .sort((a, b) => {
          if (a.address < b.address) {
            return -1;
          }
          if (a.address > b.address) {
            return 1;
          }
          return 0;
        })
        .map((department) => ({
          label: `${department.address} ${department.name}`,
          value: department.postalCode,
        })),
    [departmentsList, values.county]
  );

  const countySelectBlurHandler = () => setFieldTouched("county", true, true);

  const countySelectChangeHandler = (e: SelectItemType) => {
    setFieldValue("county", e.value, true);
    parcelMachineFields.forEach((field) => {
      setFieldValue(field, "", true);
      setFieldTouched(field, false, true);
    });
  };

  const parcelMachineSelectChangeHandler = (e: SelectItemType) => {
    const currentDepartment = departmentsList.find(
      (department) => department.postalCode === e.value
    );
    if (currentDepartment) {
      parcelMachineFields.forEach((field) => {
        setFieldValue(field, currentDepartment[field], false);
        setFieldTouched(field, false, true);
      });
    }
  };

  const parcelMachineSelectBlurHandler = () => {
    parcelMachineFields.forEach((field) => setFieldTouched(field, true, true));
  };

  const parcelMachineError =
    (touched.address && errors.address) ||
    (touched.name && errors.name) ||
    (touched.postalCode && errors.postalCode) ||
    createOrderErrorObject.address ||
    createOrderErrorObject.name ||
    createOrderErrorObject.postalCode;

  React.useEffect(() => {
    if (
      !departmentsLoading &&
      departmentsList.length < 1 &&
      !departmentsError
    ) {
      dispatch(getDepartmentsList());
    }
  }, [departmentsError, departmentsList.length, departmentsLoading, dispatch]);

  return (
    <div className={styles.block}>
      <div className={styles.block__head}>
        <div className={styles.block__headText}>
          {t("Checkout.UserInfo.DeliveryDetails")}
        </div>
      </div>
      <div className={styles.block__wrapper}>
        {currentMarket.country === Country.LT && (
          <div className={styles.block__deliveryContent}>
            <CustomSelect
              items={countySelectOptions}
              header={t("Checkout.UserInfo.City")}
              changeHandler={countySelectChangeHandler}
              contentContainerStyle="mediumInput"
              placeholder={t("Checkout.UserInfo.SelectCounty")}
              onBlurHandler={countySelectBlurHandler}
              value={values.county}
              error={errors.county}
              touched={touched.county}
              delivery
            />
            {errors.county && touched.county && (
              <div className={styles.error}>{t("Required")}</div>
            )}
            <CustomSelect
              items={parcelMachineSelectOptions}
              header={t("Checkout.UserInfo.SelectDepartment")}
              changeHandler={parcelMachineSelectChangeHandler}
              contentContainerStyle="mediumInput"
              placeholder={t("Checkout.UserInfo.SelectParcelMachine")}
              disabled={!values.county}
              onBlurHandler={parcelMachineSelectBlurHandler}
              value={values.postalCode}
              error={parcelMachineError}
              touched={touched.address || touched.postalCode || touched.name}
              delivery
            />
            {parcelMachineError && (
              <div className={styles.error}>{t("Required")}</div>
            )}
          </div>
        )}
        {currentMarket.country === Country.US && (
          <div className={styles.usDeliveryContent}>
            <CustomInput
              contentContainerStyle="fullWidthInput"
              text="Address Line 1"
              value={values.addressLine1}
              autoComplete="address-line1"
              error={errors.addressLine1}
              touched={touched.addressLine1}
              changeHandler={handleChange("addressLine1")}
              verticalError
            />
            <CustomInput
              contentContainerStyle="fullWidthInput"
              text="Address Line 2"
              value={values.addressLine2}
              autoComplete="address-line2"
              error={errors.addressLine2}
              touched={touched.addressLine2}
              changeHandler={handleChange("addressLine2")}
              verticalError
            />
            <CustomInput
              contentContainerStyle="fullWidthInput"
              text="Town/city"
              value={values.city}
              autoComplete="street-address"
              error={errors.city}
              touched={touched.city}
              changeHandler={handleChange("city")}
              verticalError
            />
            <div className={styles.usDeliveryContent__smallInputs}>
              <UsStatesDropdown
                stateValue={values.state}
                stateError={errors.state}
                stateTouched={touched.state}
                handleChange={handleChange}
                setState={(state: string) => setFieldValue("state", state)}
              />
              <CustomInput
                contentContainerStyle="mediumInput"
                text="Postal Code"
                value={values.postalCode}
                autoComplete="postal-code"
                error={errors.postalCode}
                touched={touched.postalCode}
                changeHandler={handleChange("postalCode")}
                verticalError
              />
            </div>
          </div>
        )}
        <div className={styles.day}>
          <div className={styles.day__container}>
            <p className={styles.day__titleDay}>
              {t("Checkout.UserInfo.ChooseDay")}
            </p>
            <DayPicker
              mode="single"
              selected={selected}
              onSelect={setSelected}
              styles={{
                root: { width: 308 },
                table: { border: "none" },
                tbody: { padding: 40 },
                cell: { border: "none" },
                month: {
                  border: "1px solid #E0E0E1",
                  borderRadius: 10,
                  padding: 24,
                },
                day: { fontSize: 14, fontWeight: 400 },
                caption: { background: "fff" },
                caption_between: { background: "red" },
                caption_label: { fontSize: 14 },
              }}
            />
          </div>
          <div>
            <p className={styles.day__titleTime}>
              {" "}
              {t("Checkout.UserInfo.ChooseTime")}
            </p>
            <div className={styles.block__timePickerWrap}>
              {getHours().map((value: string) => (
                <div
                  key={value}
                  className={
                    selectedTime === value
                      ? styles.block__timePickerSelected
                      : styles.block__timePicker
                  }
                  onClick={() => setSelectedTime(value)}
                >
                  {value}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.info}>
          <p className={styles.info__title}>
            {t("Checkout.UserInfo.SummaryVisit")}
          </p>
          <div className={styles.info__wrap}>
            <div className={styles.info__time}>
              <img className={styles.info__iconTime} src={timePayment} alt="" />
              <div>
                <p className={styles.info__selectedTime}>{selectedTime}</p>
                <p className={styles.info__spalio}>
                  Spalio 8d., ketvirtadienis
                </p>
              </div>
            </div>
            <div className={styles.info__geolocation}>
              <img
                className={styles.info__geolocationIcon}
                src={geolocationPayment}
                alt=""
              />
              <p className={styles.info__geolocationText}>
                Antakalnio g. 40, Vilnius, 3 aukštas, 306 kab.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryInformationBlock;
