import * as Yup from "yup";

export const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  email: Yup.string()
    .lowercase()
    .trim()
    .email("InvalidEmail")
    .required("Required"),
  sex: Yup.string().required("Required"),
  password: Yup.string()
    .min(6, "Min6")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  repeatPassword: Yup.string()
    .min(6, "Min6")
    .max(30, "Max30")
    .trim()
    .required("Required")
    .when("password", {
      is: (value: string) => value && value.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref("password"), null], "MatchPassword")
        .required("Required"),
    }),
});
