import { FC } from "react";
import styles from "./Mark.module.scss";
import { startFilled, startOutlined } from "../../../../assets/image";
import * as React from "react";

interface IMarkProps {
  mark: number;
  commentsCount: number;
}

const Mark: FC<IMarkProps> = (props) => {
  const { mark, commentsCount } = props;
  const marksArray: boolean[] = [1, 2, 3, 4, 5].map((item) => {
    return item <= mark;
  });

  const setStar = (image: string) => (
    <img className={styles.head__stars__item} src={image} alt={"star"} />
  );

  return (
    <div className={styles.head__stars}>
      {marksArray.map((item, index) => {
        return (
          <div key={`mark${index}__${item}`}>
            {item ? setStar(startFilled) : setStar(startOutlined)}
          </div>
        );
      })}
      <span className={styles.head__stars__comments}>({commentsCount})</span>
    </div>
  );
};

export default Mark;
