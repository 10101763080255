import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import style from "./PackageItem.module.scss";
import { Laboratory } from "types";
import {
  revolabLogoWithText,
  medicinaPractica,
  synlab,
  anteja,
  downloadProtocol,
  resultsArrow,
} from "assets/image";

type Props = {
  testPackage: string;
  resultDate: string;
  resultId: string;
};

const images = {
  Anteja: anteja,
  MedicinaPractica: medicinaPractica,
  Synlab: synlab,
};

export default function PackageItem({
  testPackage,
  resultDate,
  resultId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const date: Date = new Date(resultDate);

  return (
    <div className={style.wrapper}>
      <div className={style.name}>{testPackage}</div>
      <div className={style.date}>{dayjs(date).format(`YYYY.MM.DD`)}</div>
      <div className={style.buttonsContainer}>
        <div className={style.protocol}>
          <img src={downloadProtocol} alt={"downloadProtocol"} />
          {t("Dashboard.DownloadProtocol").toUpperCase()}
        </div>
        <Link className={style.link} to={`/result/${resultId}`}>
          {t("Dashboard.Results").toUpperCase()}
          <img src={resultsArrow} alt={"resultsArrow"} />
        </Link>
      </div>
    </div>
  );
}
