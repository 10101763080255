import * as React from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import styles from "./Settings.module.scss";

import CustomInput from "components/CustomInput";
import { useTypedSelector, useAppDispatch } from "hooks";
import { UserUpdateDTO } from "types";
import { UserSettingsSchema } from "validation";
import ResizableSpinner from "components/ResizableSpinner";
import { setUser } from "store/thunks";
import { clearUserState } from "store/slices";

const Settings = (): JSX.Element => {
  const { user, userError, userLoading } = useTypedSelector(
    (state) => state.user
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const setUserData = (formData: UserUpdateDTO) => {
    if (
      formData.firstName !== user.firstName ||
      formData.lastName !== user.lastName
    ) {
      dispatch(
        setUser({ firstName: formData.firstName, lastName: formData.lastName })
      );
    }

    if (formData.password && formData.repeatPassword) {
      dispatch(setUser(formData));
    }
  };

  React.useEffect(() => {
    return () => {
      dispatch(clearUserState());
    };
  }, [dispatch]);

  if (userLoading) {
    return (
      <div className={styles.settings}>
        <div className={styles.settings__title}>
          {t("Profile.Settings.Title")}
        </div>
        <div className={styles.settings__content}>
          <ResizableSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.settings}>
      <div className={styles.settings__title}>
        {t("Profile.Settings.Title")}
      </div>
      <div className={styles.settings__content}>
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            password: "",
            repeatPassword: "",
          }}
          onSubmit={(values) => setUserData(values)}
          validationSchema={UserSettingsSchema}
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => {
            return (
              <>
                {/* Disabled changing user first and last name */}
                <div className={styles.settings__head}>
                  {t("Profile.Settings.YourData")}
                </div>
                <div className={styles.settings__settingsBlock}>
                  <CustomInput
                    contentContainerStyle="changeNameInput"
                    text={t("Signup.FirstName")}
                    value={values.firstName}
                    error={errors.firstName || userError?.firstName}
                    verticalError={true}
                    touched={touched.firstName}
                    changeHandler={handleChange("firstName")}
                  />
                  <CustomInput
                    contentContainerStyle="changeNameInput"
                    text={t("Signup.LastName")}
                    value={values.lastName}
                    error={errors.lastName || userError?.lastName}
                    verticalError={true}
                    touched={touched.lastName}
                    changeHandler={handleChange("lastName")}
                  />
                </div>
                <div className={styles.settings__head}>
                  {t("Profile.Settings.Password")}
                </div>
                <div className={styles.settings__settingsBlock}>
                  <CustomInput
                    type="password"
                    contentContainerStyle="bigPassword"
                    text={t("Signup.Password")}
                    value={values.password}
                    error={errors.password || userError?.password}
                    verticalError={true}
                    touched={touched.password}
                    changeHandler={handleChange("password")}
                  />
                  <CustomInput
                    type="password"
                    contentContainerStyle="bigPassword"
                    text={t("Signup.RepeatPassword")}
                    value={values.repeatPassword}
                    error={errors.repeatPassword || userError?.repeatPassword}
                    verticalError={true}
                    touched={touched.repeatPassword}
                    changeHandler={handleChange("repeatPassword")}
                  />
                  <Link
                    className={styles.settings__changeButton}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    {t("Profile.Settings.ButtonSave")}
                  </Link>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Settings;
